<template>
  <el-dialog :title="isAdd ? '新增故障参数' : '编辑故障参数'" :visible.sync="viewVisible" width="650px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="from-body">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto">
        <el-form-item label="协议名称" prop="protocolName">
          <el-input v-model="ruleForm.protocolName"  :disabled="true"/>
        </el-form-item>
        <el-form-item label="参数名称" prop="name">
          <el-input v-model="ruleForm.name" @input="onInput"/>
        </el-form-item>
        <el-form-item label="参数代码" prop="code">
          <el-input v-model="ruleForm.code" @input="onInput"/>
        </el-form-item>
        <el-form-item label="中文显示" prop="chinese">
          <el-input v-model="ruleForm.chinese" @input="onInput"/>
        </el-form-item>
        <el-form-item label="英文显示" prop="english">
          <el-input v-model="ruleForm.english" @input="onInput"/>
        </el-form-item>
        <el-form-item label="数据类型" prop="stype">
          <el-select v-model="ruleForm.stype" @input="onInput" style="width:400px;">
            <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.name"/>
          </el-select>
        </el-form-item>
        <el-form-item label="数据范围" prop="fanwei">
          <el-input v-model="ruleForm.fanwei" @input="onInput" placeholder="用~分隔表示连续范围，分号分隔表示可选值"/>
        </el-form-item>
        <el-form-item label="首页参数" prop="homeFlag">
          <el-select v-model="ruleForm.homeFlag" placeholder="请选择" @input="onInput" style="width:400px;">
            <el-option label="显示" :value=10 />
            <el-option label="不显示" :value=20 />
          </el-select>
        </el-form-item>
        <el-form-item label="故障等级" prop="faultLevel">
          <el-select v-model="ruleForm.faultLevel" placeholder="请选择" @input="onInput" style="width:400px;" clearable>
            <el-option label="轻微" :value=10 />
            <el-option label="一般" :value=20 />
            <el-option label="严重" :value=30 />
          </el-select>
        </el-form-item>
        <el-form-item label="推送手机" prop="pushSms">
          <el-select v-model="ruleForm.pushSms" placeholder="请选择" @input="onInput" style="width:400px;">
            <el-option label="推送" :value=10 />
            <el-option label="不推送" :value=20 />
          </el-select>
        </el-form-item>
        <el-form-item label="推送APP" prop="pushApp">
          <el-select v-model="ruleForm.pushApp" placeholder="请选择" @input="onInput" style="width:400px;">
            <el-option label="推送" :value=10 />
            <el-option label="不推送" :value=20 />
          </el-select>
        </el-form-item>
        <el-form-item label="读地址码" prop="addr">
          <el-input v-model="ruleForm.addr" @input="onInput"/>
        </el-form-item>
        <el-form-item label="读功能码" prop="readCode">
          <el-input v-model="ruleForm.readCode" @input="onInput"/>
        </el-form-item>
        <el-form-item label="字节长度" prop="readLen">
          <el-input v-model="ruleForm.readLen" @input="onInput"/>
        </el-form-item>
        <el-form-item label="显示顺序" prop="position">
          <el-input v-model="ruleForm.position" @input="onInput"/>
        </el-form-item>
        <el-form-item label="读取函数" prop="readFunc">
          <el-input v-model="ruleForm.readFunc" @input="onInput"/>
        </el-form-item>
        <el-form-item label="父代号" prop="parentCode">
          <el-input v-model="ruleForm.parentCode" @input="onInput"/>
        </el-form-item>
        <el-form-item label="数据单位" prop="unit">
          <el-input v-model="ruleForm.unit" @input="onInput"/>
        </el-form-item>
        <el-form-item label="分组名称" prop="groupName">
          <el-input v-model="ruleForm.groupName" @input="onInput"/>
        </el-form-item>
        <el-form-item label="图标路径" prop="iconPth">
          <el-input v-model="ruleForm.iconPth" @input="onInput"/>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :style="{ color: buttonTextColor }" @click="handleClose">取 消</el-button>
      <el-button :style="{ color: buttonTextColor }" type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>


<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        id: null,
        protocol: this.protocol,
        code: null,
        name: null,
        chinese: null,
        english: null,
        readCode: null,
        addr: null,
        readFunc: null,
        unit: null,
        stype: null,
        fanwei: null,
        homeFlag: null,
        groupName: null,
        userFlag: null,
        readLen: null,
        parentCode: null,
        iconPth: null,
        position: 999,
        faultLevel: null,
        pushApp: null,
        pushSms: null,
        protocolName: null,
      },
      typeList: [
        {
          "name":"UINT"
        },
        {
          "name":"STRING"
        },
        {
          "name":"BOOL"
        }
      ],
      rules: {
        protocolName: [
          {
            required: true,
            message: "请确定关联协议",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请填写参数代码",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请填写参数名称",
            trigger: "blur",
          },
        ],
        chinese: [
          {
            required: true,
            message: "请填写中文名称",
            trigger: "blur",
          },
        ],
        english: [
          {
            required: true,
            message: "请填写英文名称",
            trigger: "blur",
          },
        ],
        readLen: [
          {
            required: true,
            message: "请设置字节数",
            trigger: "blur",
          },
        ],
        fanwei: [
          {
            required: true,
            message: "请指定数据范围",
            trigger: "blur",
          },
        ],
        homeFlag: [
          {
            required: true,
            message: "请指定是否首页参数",
            trigger: "blur",
          },
        ],
        stype: [
          {
            required: true,
            message: "请指定数据类型",
            trigger: "blur",
          },
        ],
        faultLevel: [
          {
            required: true,
            message: "请设定故障级别",
            trigger: "blur",
          },
        ],
        pushSms: [
          {
            required: true,
            message: "请设定是否推送短信",
            trigger: "blur",
          },
        ],
        pushApp: [
          {
            required: true,
            message: "请设定是否推送APP",
            trigger: "blur",
          },
        ],
        position: [
           {
            required: true,
            message: "请设定显示顺序",
            trigger: "blur",
          },
        ],
        addr: [
           {
            required: true,
            message: "请设定读地址码",
            trigger: "blur",
          },
        ],
        readCode: [
           {
            required: true,
            message: "请设定读功能码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    viewVisible(newVal) {
      if (newVal) {
        //新增
        if (this.isAdd) {
            this.ruleForm.id = null;
            this.ruleForm.protocol=this.protocol;
            this.ruleForm.code=null;
            this.ruleForm.name=null;
            this.ruleForm.chinese=null;
            this.ruleForm.english=null;
            this.ruleForm.readCode=null;
            this.ruleForm.addr=null;
            this.ruleForm.readFunc=null;
            this.ruleForm.unit=null;
            this.ruleForm.stype=null;
            this.ruleForm.fanwei='*/*';
            this.ruleForm.readLen=null,
            this.ruleForm.parentCode=null,
            this.ruleForm.homeFlag=null;
            this.ruleForm.groupName=null;
            this.ruleForm.position=null;
            this.ruleForm.iconPth=null;
            this.ruleForm.faultLevel=null;
            this.ruleForm.pushSms=null;
            this.ruleForm.pushApp=null;
        } else {
          //修改
          this.ruleForm.id = this.currentData.id;
          this.ruleForm.protocol=this.protocol;
          this.ruleForm.code=this.currentData.code;
          this.ruleForm.name=this.currentData.name;
          this.ruleForm.chinese=this.currentData.chinese;
          this.ruleForm.english=this.currentData.english;
          this.ruleForm.readCode=this.currentData.readCode;
          this.ruleForm.addr=this.currentData.addr;
          this.ruleForm.readFunc=this.currentData.readFunc;
          this.ruleForm.readLen=this.currentData.readLen,
          this.ruleForm.parentCode=this.currentData.parentCode,
          this.ruleForm.unit=this.currentData.unit;
          this.ruleForm.stype=this.currentData.stype;
          this.ruleForm.fanwei=this.currentData.fanwei;
          this.ruleForm.homeFlag=this.currentData.homeFlag;
          this.ruleForm.groupName=this.currentData.groupName;
          this.ruleForm.position=this.currentData.position;
          this.ruleForm.iconPth=this.currentData.iconPth;
          this.ruleForm.faultLevel=this.currentData.faultLevel;
          this.ruleForm.pushSms=this.currentData.pushSms;
          this.ruleForm.pushApp=this.currentData.pushApp;
        }
        this.originalInfo();
      }
    },
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["viewVisible","currentData","isAdd","protocol"],
  methods: {
    originalInfo() {
      this.request.originalInfo({"id":this.protocol}).then((res) => {
        if (res.code==0) {
         this.ruleForm.protocolName=res.data['name'];
         this.$forceUpdate();
        }
      });
    },
    onInput() {
      //input输入框无法编辑
      this.$forceUpdate();
    },
    handleClose() {
      this.ruleForm = {
        id: null,
        protocol: this.protocol,
        code: null,
        name: null,
        chinese: null,
        english: null,
        readCode: null,
        addr: null,
        readFunc: null,
        unit: null,
        stype: null,
        fanwei: null,
        homeFlag: null,
        groupName: null,
        readLen: null,
        parentCode: null,
        userFlag: null,
        iconPth: null,
        position: 999,
        faultLevel: null,
        pushApp: null,
        pushSms: null,
        protocolName: null,
      };
      this.$refs["ruleForm"].resetFields();
      this.$emit("viewClose");
    },
    //提交
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.request.faultAdd(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("新增成功");
                this.$emit("dictionaryList");
                this.handleClose();
              }
            });
          } else {
            this.request.faultEdit(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("编辑成功");
                this.$emit("dictionaryList");
                this.handleClose();
              }
            });
          }
        }
      });
    },
  },
};
</script>


<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}
</style>